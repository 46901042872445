import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useRecoilValue } from 'recoil';
import FadeIn from 'react-fade-in';
import { siteSettingsAtom } from '../data/atoms';

import Layout from '../components/Layout';
import { colors } from '../styles/colors';
import { logos } from '../data/logos';

// import image from '../images/me3.jpg';

import MainPhoto from '../components/MainPhoto';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SubContainer = styled.div`
  max-width: 80vw;
  margin-top: 0;
  padding: 0 5rem;

  @media (max-width: 1100px) {
    margin: 0 3rem;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin: 0px auto;
  }

  @media (max-width: 420px) {
    margin: 0px auto;
    padding: 0;
  }

  h3,
  h1 {
    margin-top: 4rem;

    @media (max-width: 768px) {
      margin-top: 2rem;
    }
  }

  h4 {
    font-weight: 300;
    padding-bottom: 5px;
  }

  .photo {
    width: 400px;
    height: 450px;
    z-index: 500;
    background-color: ${colors.darkPurple};
    padding-bottom: 0px;

    @media (max-width: 768px) {
      top: 8rem;
      left: 2rem;
      width: 200px;
      height: 220px;
    }

    @media (max-width: 420px) {
      width: 80vw;
      height: 50vh;
      position: relative;
      border: none;
      top: 0px;
      left: 0px;
      overflow: initial;
      margin: 10px auto;
      margin-top: 20px;
    }
  }

  .facts {
    margin-top: -350px;
    padding: 30px;
    padding-top: 5px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3),
      -10px -10px 50px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin-left: 18rem;

    @media (max-width: 768px) {
      margin-top: 100px;
      padding: 20px;
      margin-left: -12rem;
      margin-right: 30px;
    }

    @media (max-width: 420px) {
      margin: 0px;

      padding-left: 0px;
      box-shadow: none;
      background-color: transparent;
      backdrop-filter: none;
    }
  }

  .description,
  .subSection {
    padding-left: 2.5rem;
    @media (max-width: 768px) {
      padding-left: 3rem;
    }
    @media (max-width: 420px) {
      padding-left: inherit;
    }
  }

  .description a {
    color: inherit;
  }

  .teckStackSection {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .techStack {
    border-radius: 0.7rem;
    display: flex;
    justify-content: flex-start;
    padding: 0px;
    padding-right: 20px;
    padding-top: 20px;
  }

  .logo img {
    height: 45px;
    margin-top: 4px;
    padding-right: 5px;
  }

  .name {
    margin: auto 5px;
    font-size: 1.2rem;
  }
  .smIcons {
    margin-right: 10px;
    color: #ccc;
  }
  .location {
    margin-left: 20px;
  }
  .techStackSectionTitle {
    border-bottom: 1px solid ${colors.green};
  }
  .designSectionTitle {
    border-bottom: 1px solid ${colors.fuchsia};
  }
`;

function AboutTemplate({ data }) {
  const siteSettings = useRecoilValue(siteSettingsAtom);
  if (!siteSettings) {
    return null;
  }
  const fm = data.directus.aboutPage;
  const { image, content } = fm;
  const bgImage = getImage(image.imageFile);
  const twitterUrl = siteSettings.twitterHandle.slice(1);

  return (
    <FadeIn>
      <Container>
        <SubContainer>
          <div className="photo">
            <MainPhoto image={bgImage} width="100%">
              <p>
                <a
                  href={siteSettings.facebookUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className="smIcons"
                    icon={logos.facebook}
                    size="1x"
                  />
                </a>
                <a href={twitterUrl} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon
                    className="smIcons"
                    icon={logos.twitter}
                    size="1x"
                  />
                </a>
                <a
                  href={siteSettings.instagramUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className="smIcons"
                    icon={logos.instagram}
                    size="1x"
                  />
                </a>
                <FontAwesomeIcon
                  className="location"
                  icon={logos.location}
                  size="1x"
                />
                {' '}
                Nottinghamshire, UK
              </p>
            </MainPhoto>
          </div>

          <div className="facts">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </SubContainer>
      </Container>
    </FadeIn>
  );
}
function About({ data }) {
  return (
    <Layout>
      <AboutTemplate data={data} />
    </Layout>
  );
}

export const aboutPageQuery = graphql`
  query {
    directus {
      aboutPage {
        image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 512)
            }
          }
        }
        content
        seo {
          title
          description
          image {
            id
          }
        }
      }
    }
  }
`;

export default About;
